<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Overview</div>
            </v-card-title>
            <v-data-table
              :headers="tableHeaders"
              :items="tasks"
              no-data-text="No Tasks"
              :items-per-page="-1"
            >
              <template v-slot:item.task="{ item }">
                {{ item.task.name }}
              </template>

              <template v-slot:item.status="{ item }">
                <v-switch
                  v-model="item.status"
                  @click="openSwitch(item)"
                ></v-switch>
              </template>

              <template v-slot:item.complete_by_date="{ item }">
                {{ formatDate(item.complete_by_date) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      fields: {
        status: false,
      },
      tableHeaders: [
        { text: "Name", value: "task" },
        { text: "Status", value: "status" },
        { text: "For", value: "complete_by_date" },
      ],
    };
  },

  computed: {
    tasks() {
      let dog = this.$store.getters["lhl/dogs/get"];

      let tasks = dog.tasks.filter((c) => {
        return c.task.task_category == 2;
      });

      return tasks;
    },
  },

  methods: {
    openSwitch(task) {
      this.loading = true;
      this.errors = {};

      this.fields.status = task.status ? 1 : 0;

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        taskId: task.id,
      };

      this.$store
        .dispatch("lhl/litters/saveTaskStatus", payload)
        .then(() => {})
        .catch();
    },
  },
};
</script>
